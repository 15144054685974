import "./downloadTimeSheet.css";
import React, { useState, useRef } from "react";
import useClickOutside from "../../utils/useClickOutside";

const DownloadTimeSheet = ({ downloadTimeSheet }) => {
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(dropdownRef, () => setShowDropdown(false));
  return (
    <div className="position-relative" ref={dropdownRef}>
      <button
        className={`button dropdown-button ${showDropdown ? "active" : ""}`}
        onClick={() => setShowDropdown((prevShowDropdown) => !prevShowDropdown)}
      >
        <i className="bi bi-download"></i>
      </button>
      {showDropdown && (
        <ul className="download-dropdown">
          <li>
            <button
              className="button w-100"
              onClick={() => downloadTimeSheet("excel")}
            >
              Download Excel
            </button>
          </li>
          <hr />
          <li>
            <button
              className="button w-100"
              onClick={() => downloadTimeSheet("pdf")}
            >
              Download PDF
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default DownloadTimeSheet;
