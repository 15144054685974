import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReportHeading from "../components/ReportHeading";
import TaskBlock from "../components/TaskBlock";
import { dateInUIFormat } from "../utils/dateUtils";
import { getReportById } from "../api/apiCalls";
import reportFormatter from "../formatters/reportFormatter";
import { useApiCall } from "../middleware/useApiCall";
import { useAlert } from "../context/AlertContext";

const ReportDetails = (props) => {
  const { reportId } = useParams();
  const [report, setReport] = useState(null);
  const apiCallMiddleware = useApiCall();
  const { showErrorAlert } = useAlert();

  async function reportDetailsCall() {
    try {
      const response = await apiCallMiddleware(getReportById, reportId);
      const formattedReport = reportFormatter(response);
      setReport(formattedReport);
    } catch (error) {
      showErrorAlert(error.message);
      console.error(error);
    }
  }

  useEffect(() => {
    reportDetailsCall();
  }, []);

  return (
    <main id="main" className="main report-details">
      <div className="pagetitle d-flex justify-content-between">
        <h1>Report Details</h1>
        {report && <h2>Date: {dateInUIFormat(report.date)}</h2>}
      </div>
      {report && (
        <div className="card report-details-card flex-grow-1">
          <div className="card-body d-flex flex-column justify-content-center">
            <ReportHeading
              postedOn={report.postedOn}
              sentTo={report.sentTo}
              statusText="Submitted"
            />
            <div>
              {report.linkedTasks.map((taskBlock, i) => (
                <TaskBlock
                  key={i}
                  taskBlock={taskBlock}
                  showDescription={true}
                  showCommentInput={true}
                  reportId={report.reportId}
                  reportDate={report.date}
                />
              ))}
            </div>
            <Link
              to={-1}
              id="back-btn"
              className="btn px-5 py-2 mt-auto mb-3 rounded "
            >
              Back
            </Link>
          </div>
        </div>
      )}
    </main>
  );
};

export default ReportDetails;
