import React, { useState } from "react";
import EstimateSpentTime from "./EstimateSpentTime/EstimateSpentTime";

const TaskCreationComponent = (props) => {

  const { invalidFields } = props;
  const [showEffortDropdown, setShowEffortDropdown] = useState(false);

   const onupdate = (type, v)=>{
          switch(type){
            case "name":
              props.updateTask({"name":v, "id":props.id, "status":props.status, "description":props.description, "taskId":props.taskId, estimateTime:props.estimateTime, spentTime:props.spentTime})
            break;
            case "status":
              props.updateTask({"name":props.name, "id":props.id, "status":v, "description":props.description, "taskId":props.taskId, estimateTime:props.estimateTime, spentTime:props.spentTime})
              break;
            case "description":
              props.updateTask({"name":props.name, "id":props.id, "status":props.status, "description":v, "taskId":props.taskId, estimateTime:props.estimateTime, spentTime:props.spentTime})
              break;
            case "estimateTime":
            props.updateTask({"name":props.name, "id":props.id, "status":props.status, "description":props.description, "taskId":props.taskId, estimateTime:v, spentTime:props.spentTime})
            break;
            case "spentTime":
              props.updateTask({"name":props.name, "id":props.id, "status":props.status, "description":props.description, "taskId":props.taskId, estimateTime: props.estimateTime, spentTime:v})
              break;
          }
   }


  const render = ()=>{
    return (
    <>
      <div className="d-flex gap-2 rounded w-100">
        <input
          className={`form-control ${invalidFields && invalidFields.name ? "invalid-field" : ""}`}
          type="text"
          placeholder="Task Name"
          value={props.name}
          required
          onChange={(event) =>
            onupdate("name",event.target.value)
          }
        />
        <select
          className={`form-select w-auto ${invalidFields && invalidFields.status ? "invalid-field" : ""}`}
          value={props.status}
          onChange={(event) => {
              onupdate("status", event.target.value)
              setShowEffortDropdown(true);
            }
          }
        >
          <option value="" disabled hidden>Status</option>
          <option value="open">In Progress</option>
          <option value="created">Open</option>
          <option value="closed">Closed</option>
          <option value="help">Help Required</option>
          <option value="onhold">On Hold</option>
        </select>
        <div className="d-flex flex-row align-items-center">
          <EstimateSpentTime
            estimateTime={props.estimateTime}
            spentTime={props.spentTime}
            onupdate={onupdate}
            invalidFields={invalidFields}
            showEffortDropdown={showEffortDropdown}
            setShowEffortDropdown={setShowEffortDropdown}
          />
          <button
            type="button"
            className="task-delete-btn button"
            onClick={()=>{props.deleteTask(props.id)}}
            >
            <i className="bi bi-trash"></i>
          </button>
        </div>
      </div>
      <textarea
        className="form-control"
        placeholder="Description"
        value={props.description}
        required
        onChange={(event) =>
          onupdate("description", event.target.value)
        }
      ></textarea>
    </>
    )
  } 

  return (render())
    
};

export default TaskCreationComponent;
