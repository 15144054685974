import React from "react";
import "./timeSheetTable.css";
import { dateInUIFormat } from "../../utils/dateUtils";

const TimeSheetTable = ({ startDate, endDate, month, timeSheetData }) => {
  const totalNoOfDays = endDate.getDate() - startDate.getDate() + 1;
  const dateArray = Array.from(
    { length: totalNoOfDays },
    (_, index) => startDate.getDate() + index
  );
  const rows = timeSheetData.map((userData, i) => (
    <TimeSheetRow key={i} dateArray={dateArray} userData={userData} />
  ));

  return (
    <div className="overflow-auto">
      <table className="time-sheet-table rounded">
        <thead>
          <tr>
            <th className="text-center">Name</th>
            <th colSpan={totalNoOfDays} className="text-center">
                Dates: {dateInUIFormat(startDate)} to {dateInUIFormat(endDate)}
            </th>
            <th className="text-center">Total Hours</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            {dateArray.map((date) => (
              <td className="fw-bold date-cell" key={date}>
                {date}
              </td>
            ))}
            <td></td>
          </tr>
          {rows}
        </tbody>
      </table>
    </div>
  );
};

function TimeSheetRow({ dateArray, userData }) {
  const totalHours = Object.values(userData.hours).reduce(
    (hour, total) => total + hour,
    0
  );
  const hoursArray = dateArray.map((date) => (
    <td className="date-cell" key={date}>
      {userData.hours[date] || 0}
    </td>
  ));
  return (
    <tr>
      <td className="text-center">{userData.user.name}</td>
      {hoursArray}
      <td className="text-center fw-bold">{totalHours}</td>
    </tr>
  );
}

export default TimeSheetTable;
