import React from "react";
import Task from "./Task/Task";

const TaskBlock = ({ taskBlock, showDescription, showCommentInput, reportId, reportDate }) => {
  return (
    <div className="task-block rounded">
      <h6 className="task-title text-center border border-2 rounded-top">{taskBlock.date}</h6>
      <div className="task-body border border-top-0 border-2 rounded-bottom">
        {taskBlock.tasks.map((task, i) => (
          <Task key={i} {...task} showDescription={showDescription} showCommentInput={showCommentInput} reportId={reportId} reportDate={reportDate}/>
        ))}
      </div>
    </div>
  );
};

export default TaskBlock;
