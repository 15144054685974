import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PublicNavbar from "../components/PublicNavbar";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";

const PublicLayout = () => {
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: "instant"});
  }, [location]);

  if (initialized) {
    if (keycloak.authenticated) {
      navigate("/dashboard");
      return;
    }
    return (
      <div className="App">
        <Header publicHeader={true} />
        <PublicNavbar />
        <Outlet />
        <Footer />
      </div>
    );
  }
};

export default PublicLayout;
